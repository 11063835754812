<template>
  <div>
    <div class="tile is-ancestor">
      <div class="tile is-parent">

        <spinner v-show="loading"></spinner>
        <article class="tile is-child box" v-if="!loading && item">

          <h1 class="title">Расписание врача</h1>
          <h2 class="subtitle" v-if="!isNew && item.doctor">{{ item.doctor.full_name }}</h2>

          <form class="block" @submit.prevent="save">

            <div class="control is-horizontal">
              <div class="control-label">
                <label class="label">Врач</label>
              </div>
              <div class="control">
                  <multiselect v-model="item.doctor"
                     :options="doctors"
                     label="full_name"
                     :allow-empty="true"
                     :multiple="false"
                     placeholder="Выберите врача"
                     track-by="uuid"
                     v-if="doctors"
                     :class="{ 'is-danger': $v.item.doctor.$error }"
                     @input="$v.item.doctor.$touch()"
                  >
                  </multiselect>
                  <!--<select v-model="item.doctor"-->
                          <!--:class="{ 'is-danger': $v.item.doctor.$error }"-->
                          <!--@input="$v.item.doctor.$touch()"-->
                  <!--&gt;-->
                    <!--<option :value="null">Выберите врача</option>-->
                    <!--<option v-for="doctor in doctors" :value="doctor">-->
                      <!--{{ doctor.full_name }}-->
                    <!--</option>-->
                  <!--</select>-->
              </div>
            </div>

            <div class="control is-horizontal">
              <div class="control-label">
                <label class="label">Специальность</label>
              </div>
              <div class="control">
                <multiselect v-model="item.speciality"
                   :options="specialities"
                   label="name"
                   :allow-empty="true"
                   :multiple="false"
                   placeholder="Выберите специальность врача"
                   track-by="uuid"
                   v-if="specialities"
                   :class="{ 'is-danger': $v.item.speciality.$error }"
                   @input="$v.item.speciality.$touch()"
                >
                </multiselect>
                <!--<span class="select">-->
                  <!--<select v-model="item.speciality"-->
                          <!--:class="{ 'is-danger': $v.item.speciality.$error }"-->
                          <!--@input="$v.item.speciality.$touch()"-->
                  <!--&gt;-->
                    <!--<option :value="null">Выберите специальность врача</option>-->
                    <!--<option v-for="speciality in specialities" :value="speciality">-->
                      <!--{{ speciality.name }}-->
                    <!--</option>-->
                  <!--</select>-->
                <!--</span>-->
              </div>
            </div>

            <div class="control is-horizontal">
              <div class="control-label">
                <label class="label">Клиника</label>
              </div>
              <div class="control">
                <multiselect v-model="item.clinic"
                   :options="clinics"
                   label="name"
                   :allow-empty="true"
                   :multiple="false"
                   placeholder="Выберите клинику"
                   track-by="uuid"
                   v-if="clinics"
                   :class="{ 'is-danger': $v.item.clinic.$error }"
                   @input="$v.item.clinic.$touch()"
                >
                </multiselect>
                <!--<span class="select">-->
                  <!--<select v-model="item.clinic"-->
                    <!--:class="{ 'is-danger': $v.item.clinic.$error }"-->
                    <!--@input="$v.item.clinic.$touch()"-->
                  <!--&gt;-->
                    <!--<option :value="null">Выберите клинику</option>-->
                    <!--<option v-for="clinic in clinics" :value="clinic">-->
                      <!--{{ clinic.name }}-->
                    <!--</option>-->
                  <!--</select>-->
                <!--</span>-->
              </div>
            </div>

            <div class="control is-horizontal">
              <div class="control-label">
                <label class="label">SubjId</label>
              </div>
              <div class="control">
                <input class="input" type="number"
                   v-model="item.subj_id"
                   placeholder="Id расписания?"
                   :class="{ 'is-danger': $v.item.subj_id.$error }"
                   @input="$v.item.subj_id.$touch()"
                >
              </div>
            </div>

            <div class="control is-horizontal">
              <div class="control-label">
                <label class="label">Длительность приема</label>
              </div>
              <div class="control">
                <input class="input" type="number"
                   v-model="item.duration"
                   :class="{ 'is-danger': $v.item.duration.$error }"
                   @input="$v.item.duration.$touch()"
                >
              </div>
            </div>

            <div class="control is-horizontal">
              <div class="control-label">
                <label class="label"></label>
              </div>

              <div class="control is-grouped">
                <p class="control">
                  <button class="button is-primary"
                      :class="{ 'is-loading': saving, 'is-disabled': $v.$invalid }"
                  >
                    Сохранить
                  </button>
                </p>
                <p class="control">
                  <router-link :to="{ name: 'ScheduleOfDoctors'}" class="button is-link" exact>Отменить</router-link>
                </p>
              </div>

            </div>

          </form>
        </article>

      </div>
    </div>
  </div>
</template>

<script>
import { required, between, numeric } from 'vuelidate/lib/validators'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import doctorsService from '../../services/doctors'
import specialitiesService from '../../services/specialities'
import clinicsService from '../../services/clinics'
import scheduleOfDoctorsService from '../../services/scheduleOfDoctors'

export default {
  mixins: [createOrUpdateViewMixin(scheduleOfDoctorsService, {
    doctors: null,
    specialities: null,
    clinics: null
  })],

  beforeRouteEnter (to, from, next) {
    let specialities = specialitiesService.getAll({ limit: 50000 })
    let doctors = doctorsService.getAll({ limit: 50000 })
    let clinics = clinicsService.getAll({ limit: 50000 })

    Promise.all([doctors, specialities, clinics]).then(
      (values) => {
        next(vm => {
          vm.doctors = values[0].doctors
          vm.specialities = values[1].specialities
          vm.clinics = values[2].clinics
        })
      },
      () => { next(false) }
    )
  },

  validations: {
    item: {
      doctor: {
        required
      },
      speciality: {
        required
      },
      clinic: {
        required
      },
      duration: {
        required: required,
        between: between(1, 60 * 24),
        numeric: numeric
      },
      subj_id: {
        required: required,
        numeric: numeric,
        between: between(1, Number.MAX_VALUE)
      }
    }
  }
}
</script>
